// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-cookie-tsx": () => import("./../../../src/pages/legal/cookie.tsx" /* webpackChunkName: "component---src-pages-legal-cookie-tsx" */),
  "component---src-pages-legal-monday-apps-eula-tsx": () => import("./../../../src/pages/legal/monday-apps-eula.tsx" /* webpackChunkName: "component---src-pages-legal-monday-apps-eula-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-products-mirror-column-automations-monday-app-index-tsx": () => import("./../../../src/pages/products/mirror-column-automations-monday-app/index.tsx" /* webpackChunkName: "component---src-pages-products-mirror-column-automations-monday-app-index-tsx" */),
  "component---src-pages-products-search-in-updates-index-tsx": () => import("./../../../src/pages/products/search-in-updates/index.tsx" /* webpackChunkName: "component---src-pages-products-search-in-updates-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-monday-apps-development-index-tsx": () => import("./../../../src/pages/services/monday-apps-development/index.tsx" /* webpackChunkName: "component---src-pages-services-monday-apps-development-index-tsx" */),
  "component---src-pages-services-product-development-index-tsx": () => import("./../../../src/pages/services/product-development/index.tsx" /* webpackChunkName: "component---src-pages-services-product-development-index-tsx" */),
  "component---src-pages-services-team-extension-index-tsx": () => import("./../../../src/pages/services/team-extension/index.tsx" /* webpackChunkName: "component---src-pages-services-team-extension-index-tsx" */),
  "component---src-pages-vacancies-index-tsx": () => import("./../../../src/pages/vacancies/index.tsx" /* webpackChunkName: "component---src-pages-vacancies-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-posts-tsx": () => import("./../../../src/templates/blog-posts.tsx" /* webpackChunkName: "component---src-templates-blog-posts-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-vacancy-tsx": () => import("./../../../src/templates/vacancy.tsx" /* webpackChunkName: "component---src-templates-vacancy-tsx" */)
}

