require(`smoothscroll-polyfill`);

export const onClientEntry = async () => {
  //   if (!("scrollBehavior" in document.documentElement.style))
  //     import(`smoothscroll-polyfill`);
  // this we have to await
  if (typeof window.IntersectionObserver === `undefined`)
    await import(`intersection-observer`);
};

export const onRouteUpdate = ({ location }) => {
  if (
    process.env.NODE_ENV !== `production` ||
    typeof window.gtag !== `function`
  ) {
    return null;
  }

  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  };

  // react-helmet fix
  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    setTimeout(sendPageView, 40);
  }

  return null;
};
